export enum ReportAreaGrouping {
    None = 'None',
    Corporate = 'Corporate',
    DivisionManager = 'Division Manager',
    Division = 'Division',
    RegionManager = 'Region Manager',
    Region = 'Region',
    Salesperson = 'Salesperson',
    Territory = 'Territory',
    //TradingArea = 'Trading Area',
    Customer = 'Customer',
    Channel = 'Channel',
    Product = 'Product'
}