import { Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ValueCalculatorJobInfoPage } from './value-calculator-job-info.page';
import { ValueCalculatorService } from '../services/value-calculator.service';
import { BandsawMachineListModel } from '../models/ValueCalculator/BandsawMachineListModel';

export abstract class ValueCalculatorMachineInfoPage extends ValueCalculatorJobInfoPage {
  jobMachines: any[] = [];
  machineId: number = null;
  machine: BandsawMachineListModel;
  machineIdString: string;

  canAddAnotherMachine: boolean;

  constructor(
    @Inject(ActivatedRoute) route: ActivatedRoute,
    @Inject(ValueCalculatorService) valueCalculatorService: ValueCalculatorService,
  ) {
    super(route, valueCalculatorService);
  }

  getMachineInfo() {
    const passedMachineData = sessionStorage.getItem('machineData');
    this.machineIdString = this.route.snapshot.paramMap.get('machineId');
    if (this.machineIdString) {
      this.machineId = +this.machineIdString;
    }
    if (passedMachineData) {
      console.log('passedMachineData');
      console.log(passedMachineData);
      const machineData = JSON.parse(passedMachineData);
      if (machineData.vcJobDataId == this.jobDataId && (machineData.Id == this.machineId || (!this.machineId && this.machineId != 0))) {
        this.machine = machineData;
        this.onMachineLoaded();
      } else {
        this.getMachineInfoByMachineId();
      }
    } else {
      this.getMachineInfoByMachineId();
    }
  }

  getMachineInfoByMachineId() {
    if (this.jobDataId > 0 && this.machineId > 0) {
      this.valueCalculatorService.getMachineDatabyJobId(this.jobDataId).subscribe(machineList => {
        if (machineList.length > 0) {
          this.jobMachines = machineList;
          this.canAddAnotherMachine = this.jobMachines && this.jobMachines.length <= 2;
          let matchingMachine = machineList;
          if (this.machineId > 0) {
            matchingMachine = matchingMachine.filter(x => x.Id == this.machineId);
          }
          if (matchingMachine[0]) {
            const machine: BandsawMachineListModel = matchingMachine[0];
            machine['bladeLength'] = null;
            this.machine = machine;
            this.onMachineLoaded();
          }
        }
      });
    } else {
      this.machine = new BandsawMachineListModel();
    }
  }

  abstract onMachineLoaded();
}
