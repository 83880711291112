export class BandsawMachineListModel {
    Id: number;
    vcJobDataId: number;
    machine_models_Id: number;
    morseBladeDataId: number;
    competitorBladeDataId: number;
    numberofMachines: number;
    is_deleted: number;
    createdBy: number;
    createDate: number;
    modifiedBy: number;
    modifiedDate: number;

    BladeChangeoverTime: number;
    NumberOfOperators: number;
    CapacityUtilization: number;

    manufacturer_id: number;
    bwBladeWidthId: number;
    // There are more properties now we should sort this out - or add a new model
    // TODO also capitalization differs
}