import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ValidationService {

  constructor() { }

  unitBasedFieldValidator(isMetricField: boolean, isMetricMode: boolean, validationFunction: IsValidFunction) : ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (isMetricMode == isMetricField && !validationFunction(control)) {
        return { key: 'error' };
      } else {
        return null;
      }
    };
  }

  requiredFieldValid(control: AbstractControl, extraConditions: boolean) {
    if (this.hasValue(control) && extraConditions) {
      return true;
    } else {
      return false;
    }
  }

  hasValue(control: AbstractControl) {
    if (!control.value
      && control.value != 0) {
      return false;
    } else {
      return true;
    }
  }
}

type IsValidFunction = (control: AbstractControl) => boolean;