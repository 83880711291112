import { Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ValueCalculatorBusinessInfoPage } from './value-calculator-business-info.page';
import { JobDataModel } from '../models/ValueCalculator/JobDataModel';
import { ValueCalculatorService } from '../services/value-calculator.service';

export abstract class ValueCalculatorJobInfoPage extends ValueCalculatorBusinessInfoPage {
  jobDataId: number = null;
  jobData: JobDataModel = null;

  constructor(
    @Inject(ActivatedRoute) route: ActivatedRoute,
    @Inject(ValueCalculatorService) valueCalculatorService: ValueCalculatorService,
  ) {
    super(route, valueCalculatorService);
  }

  getJobInfo() {
    const passedJobData = sessionStorage.getItem('jobData');
    this.jobDataId = +this.route.snapshot.paramMap.get('jobId');
    if (passedJobData) {
      console.log('passedJobData');
      console.log(passedJobData);
      const jobData = JSON.parse(passedJobData);
      if (jobData.Id == this.jobDataId) {
        this.jobData = jobData;
      } else {
        this.getJobInfoByBusinessAnalysisId();
      }
    } else {
      this.getJobInfoByBusinessAnalysisId();
    }
  }

  getJobInfoByBusinessAnalysisId() {
    if (this.businessAnalysisId) {
      this.valueCalculatorService.getJobDatabyBusinessAnalysisId(this.businessAnalysisId)
      .subscribe(jobs => {
        const jobData = jobs[0];
        this.jobData = jobData;
        if (jobData) {
          this.jobDataId = jobData.Id;
        }
        this.onJobInfoLoaded();
      });
    }
  }

  abstract onJobInfoLoaded();

}
