import { Order } from 'src/app/models/Order';
import { OdpService } from 'src/app/services/odp.service';
import { TrackingResult } from 'src/app/models/TrackingResult';
import { ModalController } from '@ionic/angular';
import { TrackingInfoPage } from 'src/app/pages/modals/tracking-info/tracking-info.page';
import { OrderDetail } from 'src/app/models/OrderDetail';

export abstract class OrderPage {

    constructor(
        protected odpService: OdpService,
        protected modalController: ModalController
        ) {
    }
  
    showTracking(orderStatus: string): boolean {
        if (orderStatus == 'COMPLETE' || orderStatus == 'BACKORDER') {
            return true;
        } else {
            return false;
        }
    }

    onTrack(orderNumber: string) {
        this.odpService.getTrackingInfo(orderNumber).subscribe(results => { 
          if (results.length > 1 || (results.length == 1 && !results[0].TrackingUrl)) {
              this.openAddEvent(results);
          } else {
            // Link to external site
            window.open(results[0].TrackingUrl);
          }
        })
      }
    
      async openAddEvent(trackingResults: TrackingResult[]) {
        // Create a modal using MyModalComponent with some initial data
        const modal = await this.modalController.create({
            component: TrackingInfoPage,      
            componentProps: {
              'trackingResults': trackingResults
            },
            cssClass: 'table-modal'
        });
        modal.onDidDismiss().then(() => {
          
        });
        modal.present();
      }
  }