import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { BehaviorSubjectService } from './behavior-subject.service';
import { Observable } from 'rxjs';
import { MainDashboardRgaView } from '../models/MainDashBoardRgaView';
import { map } from 'rxjs/operators';
import { RGADetails } from '../models/RGADetails';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json' }),
};

@Injectable({
  providedIn: 'root'
})
export class RgaService {
  private url: string;

  constructor(
    private http: HttpClient,
    behaviorSubjectService: BehaviorSubjectService) {
      behaviorSubjectService.morseAPISubject.subscribe(url => {this.url = url});
  }

  getCustomerRGAs(customerNumber: string, searchStart: Date, searchEnd: Date, isActive: boolean): Observable<MainDashboardRgaView[]> {
    const url = `${this.url}/GetCustomerRGAs`;
    const params = new HttpParams()
                    .set('customerno', customerNumber)
                    .set('searchStart', searchStart != null ? searchStart.toString() : 'null')
                    .set('searchEnd', searchEnd  != null ? searchEnd.toString() : 'null')
                    .set('isActive', isActive != null ? isActive.toString() : 'null');
    const options = httpOptions;
    options['params'] = params;
    return this.http.get<MainDashboardRgaView[]>(url, options);
  }

  getRGADetails(rgaNumber: string): Observable<RGADetails> {
    const url = `${this.url}/GetRGADetails`;
    const params = new HttpParams().set('RGANumber', rgaNumber);
    const options = httpOptions;
    options['params'] = params;
    return this.http.get<RGADetails>(url, options);
  }

  getRGADetailsforDistributor(rgaNumber: string): Observable<RGADetails> {
    const url = `${this.url}/GetRGADetailsforDistributor`;
    const params = new HttpParams().set('RGANumber', rgaNumber);
    const options = httpOptions;
    options['params'] = params;
    return this.http.get<RGADetails>(url, options);
  }

  addRgaRequest(request: any): Observable<any> {
    return this.http.post(this.url + '/AddRgaRequest', request, httpOptions);
  } 
}
