import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { BehaviorSubjectService } from './behavior-subject.service';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { DownloadableFile } from '../models/downloadable-file';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json' }),
};

@Injectable({
  providedIn: 'root'
})
export class SawInfoService {
  private url: string;

  constructor(private http: HttpClient, behaviorSubjectService: BehaviorSubjectService) { 
    behaviorSubjectService.morseAPISubject.subscribe(url => this.url = url);
  }

  public getSawManualDirectoryList() {
    return this.http.get<string>(`${this.url}/GetSawManualDirectories`).pipe(map((result) => JSON.parse(result)));
  }

  public getSawManualFilesByMachineName(name: string) {
    const linkerFunc = (s: string) => this.stringToLink(s, name);

    const headers = httpOptions.headers;
    const options = {
      headers,
      params: {
        manufacturer: name
      }
    };

    return this.http.get<string>(`${this.url}/GetSawManualFilesByMachineName`, options)
        .pipe<string[]>(map((result) => JSON.parse(result)))
        .pipe<DownloadableFile[]>(map<string[], DownloadableFile[]>(result => {
          console.log(result.map<DownloadableFile>(linkerFunc));
          return result.map<DownloadableFile>(linkerFunc);
        }));
  }

  private stringToLink(item: string, maker: string): DownloadableFile {
    const itemExtension = item.slice(item.lastIndexOf('.') + 1, item.length);
    const itemNoExtension = item.slice(0, -1 * (itemExtension.length + 1));
    return {
      fileName: item,
      fileLink: `${this.url}/GetSawFile/` +
      `${encodeURIComponent(maker)}/${encodeURIComponent(itemNoExtension)}/${encodeURIComponent(itemExtension)}`,
      currentlyDownloading: false
    };
  }

  public requestFileWithToken(url) {
    return this.http.get<Blob>(url, {responseType: 'blob' as 'json'});
  }
}
